import React, { useEffect } from 'react';
import './Modal.css';

const Modal = ({ show, handleClose }) => {
  const TEST_PLATFORM_FEE_AND_ACCOUNTS = {
    referralAccount: "9stQCk5kpEwqs1pmNLyPeR9f8yiEuPLfQn1VDsLJ5FNx",
    feeBps: 1, // Minimum possible fee
  };

  useEffect(() => {
    if (show) {
      window.Jupiter.init({
        endpoint: "https://rpc.hellomoon.io/be5b61c1-6383-4d23-a3bc-43c5bf1bdc58",
        strictTokenList: false,
        formProps: {
          fixedOutputMint: true,
          initialOutputMint: "3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z",
        },
        platformFeeAndAccounts: TEST_PLATFORM_FEE_AND_ACCOUNTS, // Add platform fee and accounts
      });
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div id="jupiter-terminal"></div>
      </div>
    </div>
  );
};

export default Modal;
