import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import './Main.css';
import Modal from './Modal';

const Main = () => {
  const [stocks, setStocks] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const stockPriceIds = [
    "0x6f9cd89ef1b7fd39f667101a91ad578b6c6ace4579d5f7f285a4b06aa4504be6", // GME
    "0x5b1703d7eb9dc8662a61556a2ca2f9861747c3fc803e01ba5a8ce35cb50a13a1", // AMC
    "0x49f6b65cb1de6b10eaf75e7c03ca029c306d0357e91b5311b175084a5ad55688", // AAPL
    "0xe65ff435be42630439c96396653a342829e877e2aafaeaf1a10d0ee5fd2cf3f2", // GOOGL
    "0xb5d0e0fa58a1f8b81498ae670ce93c872d14434b72c364885d4fa1b257cbb07a", // AMZN
    "0xd0ca23c1cc005e004ccf1db5bf76aeb6a49218f43dac3d4b275e92de12ded4d1", // MSFT
    "0x16dad506d7db8da01c87581c87ca897a012a153557d4d578c3b9c9e1bc0632f1"  // TSLA
  ];

  const commodityPriceIds = [
    "0xd00bd77d97dc5769de77f96d0e1a79cbf1364e14d0dbf046e221bce2e89710dd", // OIL(USO)
    "0x765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2", // GOLD
  ];

  const tickerMapping = {
    "6f9cd89ef1b7fd39f667101a91ad578b6c6ace4579d5f7f285a4b06aa4504be6": "GME",
    "5b1703d7eb9dc8662a61556a2ca2f9861747c3fc803e01ba5a8ce35cb50a13a1": "AMC",
    "49f6b65cb1de6b10eaf75e7c03ca029c306d0357e91b5311b175084a5ad55688": "AAPL",
    "e65ff435be42630439c96396653a342829e877e2aafaeaf1a10d0ee5fd2cf3f2": "GOOG",
    "b5d0e0fa58a1f8b81498ae670ce93c872d14434b72c364885d4fa1b257cbb07a": "AMZN",
    "d0ca23c1cc005e004ccf1db5bf76aeb6a49218f43dac3d4b275e92de12ded4d1": "MSFT",
    "16dad506d7db8da01c87581c87ca897a012a153557d4d578c3b9c9e1bc0632f1": "TSLA",
    "d00bd77d97dc5769de77f96d0e1a79cbf1364e14d0dbf046e221bce2e89710dd": "US Oil Fund LP",
    "765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2": "Gold",
  };

  const endpoint = "https://hermes.pyth.network/v2/updates/price/latest"; // Ensure this is the correct endpoint

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const stockData = await fetchPriceFeeds(stockPriceIds);
        const formattedStockData = formatPriceFeeds(stockData);
        setStocks(formattedStockData);

        const commodityData = await fetchPriceFeeds(commodityPriceIds);
        const formattedCommodityData = formatPriceFeeds(commodityData);
        setCommodities(formattedCommodityData);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, []);

  const fetchPriceFeeds = async (priceIds) => {
    const url = `${endpoint}?${priceIds.map(id => `ids%5B%5D=${id.replace('0x', '')}`).join('&')}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch price feeds');
    }

    const data = await response.json();
    return data.parsed;
  };

  const formatPriceFeeds = (priceFeeds) => {
    return priceFeeds.map(feed => {
      const normalizedId = feed.id.replace('0x', '').toLowerCase();
      return {
        id: feed.id,
        name: tickerMapping[normalizedId] || feed.id, // Use the ticker name from the mapping
        value: (feed.price.price / Math.pow(10, -feed.price.expo)).toFixed(2),
        change: (Math.random() * 0.02 - 0.01).toFixed(4),
        percentage: ((Math.random() * 2 - 1).toFixed(2)) + '%', // Placeholder for real percentage calculation
      };
    });
  };

  const simulatePriceUpdates = (items) => {
    return items.map(item => {
      const change = (Math.random() * 0.02 - 0.01).toFixed(4);
      const newValue = (parseFloat(item.value) + parseFloat(change)).toFixed(2);
      const percentage = ((change * 100) / newValue).toFixed(2) + '%';
      return { ...item, value: newValue, change, percentage };
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setStocks(prevStocks => simulatePriceUpdates(prevStocks));
      setCommodities(prevCommodities => simulatePriceUpdates(prevCommodities));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    $('table.stocks tbody').empty();
    stocks.forEach((stock) => {
      const rowClass = stock.change >= 0 ? 'increase' : 'decrease';
      $('table.stocks tbody').append(
        `<tr class="stock ${rowClass}">
          <td class="name">${stock.name}</td>
          <td class="value">${stock.value}</td>
          <td class="change">${stock.change}</td>
          <td class="percentage">${stock.percentage}</td>
        </tr>`
      );
    });

    $('table.commodities tbody').empty();
    commodities.forEach((commodity) => {
      const rowClass = commodity.change >= 0 ? 'increase' : 'decrease';
      $('table.commodities tbody').append(
        `<tr class="commodity ${rowClass}">
          <td class="name">${commodity.name}</td>
          <td class="value">${commodity.value}</td>
          <td class="change">${commodity.change}</td>
          <td class="percentage">${commodity.percentage}</td>
        </tr>`
      );
    });
  }, [stocks, commodities]);

  const openModal = () => {
    // Ensure the modal is closed and then open it
    setShowModal(false);
    setTimeout(() => {
      setShowModal(true);
    }, 0);
  };

  return (
    <div>
      <div className="green-bar">
        Trading powered by Jupiter. Our stock token $____ will launch with Jupiters next update.
      </div>
      <div className="header">
        <div className="logo">
          <a href="">
            <img src="borgy.gif" alt="Logo" />
          </a>
        </div>
      </div>
      <h1>THE SWISS <span className="interjection">BORG&nbsp;&nbsp;MEME</span> STOCK EXCHANGE</h1>
      <div className="connect-button-container">
        <button className="connect-button shake-animation" onClick={openModal}>[ TRADE ]</button>
      </div>
      <h4>1. Connect SOL wallet</h4>
      <h4>2. Trade assets for $BORG</h4>
      <h4>3. Earn $____ stock tokens</h4>
      <div className="table-responsive">
        <h3>Stocks</h3>
        <table className="tablesorter stocks">
          <thead>
            <tr>
              <th>Market</th>
              <th>Price</th>
              <th>Change</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <div className="table-responsive">
        <h3>Commodities</h3>
        <table className="tablesorter commodities">
          <thead>
            <tr>
              <th>Market</th>
              <th>Price</th>
              <th>Change</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <Modal show={showModal} handleClose={() => setShowModal(false)} />
    </div>
  );
};

export default Main;
